import * as yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const jpLocale = {
  mixed: {
    required: (param) =>
      `${param.label ? param.label + "は" : "この項目は"}入力が必須です`,
  },
  string: {
    length: (param) => `${param.length}文字で入力してください`,
    min: (param) => `${param.min}文字以上で入力してください`,
    max: (param) => `${param.max}文字以下で入力してください`,
    email: "メールアドレスを入力してください",
    matches: () => "正しく入力してください",
  },
  number: {
    min: (param) => `${param.min}以上の数値を入力してください`,
    max: (param) => `${param.max}以下の数値を入力してください`,
  },
  date: {
    min: (param) => `${param.min}より後の日付を入力してください`,
    max: (param) => `${param.max}より前の日付を入力してください`,
  },
  array: {
    min: (param) => `${param.min}個以上を選択してください`,
  },
};

yup.setLocale(jpLocale);

yup.addMethod(yup.string, "dateLike", function (format = "YYYY/MM/DD") {
  return this.test({
    name: "dateLike",
    message: "正しい日付を入力してください",
    test: (value) =>
      value === "" ? true : dayjs(value, format, true).isValid(),
  });
});

yup.addMethod(yup.string, "phone", function () {
  return this.test({
    name: "phone",
    message: "正しい電話番号を入力してください",
    test: (value) =>
      value === undefined || value === ""
        ? true
        : /^[0-9]{1,9}-[0-9]{1,9}-[0-9]{1,9}$/.test(value),
  });
});

yup.addMethod(yup.string, "zipCode", function () {
  return this.test({
    name: "zipCode",
    message: "正しい郵便番号を入力してください",
    test: (value) =>
      value === undefined || value === ""
        ? true
        : /^[0-9]{3}-[0-9]{4}$/.test(value),
  });
});

yup.addMethod(yup.string, "katakana", function () {
  return this.test({
    name: "katakana",
    message: "カタカナで入力してください",
    test: (value) =>
      value === undefined || value === ""
        ? true
        : /^[\u30a0-\u30ff]+$/.test(value),
  });
});

export default yup;
