import React from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "@emotion/styled";
import SitetitleSp from "@/ui/images/sitetitle_sp.png";
import { Footer } from "@/ui/components/panes/Footer/Footer";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
`;

const Wrapper = styled.div`
  background-color: #002664;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const PageTitle = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  display: block;
  width: 215px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  img {
    display: block;
    width: 215px;
  }
`;

const Main = styled.div`
  padding-bottom: 80px;

  h1 {
    width: 91.6030534351%;
    max-width: 700px;
    background-color: #4fb4ca;
    border-radius: 16px 16px 0px 0px;
    text-align: center;
    font-size: 1.125rem;
    color: #fff;
    padding: 12px 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  width: 91.6030534351%;
  max-width: 700px;
  position: relative;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  padding: 37px 0 80px 0;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;

  h2 {
    border-bottom: solid 1px #002664;
    font-size: 1rem;
    line-height: 1em;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
`;

const Skyblue = styled.span`
  color: #4fb4ca;
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-bottom: 1em;
`;

const UlCircle = styled.ul`
  list-style-type: disc;
  padding-left: 1em;

  li {
    font-size: 0.875rem;
    line-height: 1.625rem;
  }
`;

const Button = styled.button`
  position: absolute;
  bottom: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 175px;
  border-radius: 500px;
  background-color: #4fb4ca;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 1.125rem;
  padding: 13px 0;
`;

const Link = styled.a`
  color: #4fb4ca;
  word-break: break-all;
  font-size: 0.875rem;
  line-height: 1.625rem;

  &:visited {
    color: #4fb4ca;
  }
`;

export const InformationPrivacy = () => {
  const navigate = useNavigate();

  const goTopAndScroll = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  return (
    <Wrapper>
      <PageTitle onClick={() => navigate("/")}>
        <img src={SitetitleSp} alt="個人情報の取り扱いについて" />
      </PageTitle>
      <Main>
        <h1>個人情報の取り扱いについて</h1>
        <Content>
          <Section>
            <Text>
              <Skyblue>あなただけに特別ご招待キャンペーン</Skyblue>
              （以下「本キャンペーン」）に関し、ご提供頂く皆様の個人情報を
              <Skyblue>あなただけに特別ご招待キャンペーン事務局</Skyblue>
              （以下「当事務局」）は、以下のとおり取り扱いいたします。
            </Text>
          </Section>
          <Section>
            <h2>１.ご提供いただく個人情報について</h2>
            <Text>
              当事務局は、利用者に当事務局のサービス等に関する情報を提供するため、利用者のお名前・住所・生年月日・性別・電話番号・e-mailアドレスといった個人情報を、利用者よりご提供いただきます。
            </Text>
          </Section>
          <Section>
            <h2>２.個人情報の管理について</h2>
            <Text>
              利用者が提供した個人情報は、当事務局が個人情報保護に関する社内規定に従い適切に管理いたします。
            </Text>
          </Section>
          <Section>
            <h2>３.個人情報の取得と利用目的について</h2>
            <Text>
              当事務局は、本キャンペーンサイトより、利用者の個人情報を取得します。利用者の個人情報の利用目的については、以下のとおりとします。ただし、利用目的の範囲を超えて利用する場合は、事前に適切な方法で利用者からの同意を得るものとします。
            </Text>
            <UlCircle>
              <li>
                本キャンペーンにおける利用者への情報提供と申込者へ申請時の個人認証
              </li>
              <li>
                当事務局による本キャンペーン参加の意向確認を目的としたご案内のメール等の通知の実施
              </li>
              <li>各種お問い合わせに対する返答</li>
              <li>賞金の振込</li>
            </UlCircle>
          </Section>
          <Section>
            <h2>４.委託について</h2>
            <Text>
              当事務局は、取得した個人情報を、公表または通知した利用目的を達成するために業務委託する場合があります。
              この場合において、当事務局は業務委託先に対し、当事務局が委託した個人情報の適正な取り扱いを求めるとともに適切な管理をいたします。
            </Text>
          </Section>
          <Section>
            <h2>５.第三者への提供について</h2>
            <Text>
              事前に利用者の同意をいただかない限り、当事務局は利用者の個人情報を第三者に開示または提供いたしません。ただし、人命や人権を保護するために緊急を要する場合、または司法機関、警察等の公共機関による法令に基づく要請に協力する場合、その他法令に従う場合には、利用者の同意を得ることなく情報を開示または提供することがあります。
            </Text>
          </Section>
          <Section>
            <h2>６.免責について</h2>
            <Text>
              以下の場合は、当事務局は一切責任を負わないものとします。
            </Text>
            <UlCircle>
              <li>
                利用者自らが本キャンペーン上の機能または別に手段を用いて他の利用者に個人情報を明らかにした場合
              </li>
              <li>
                活動情報またはその他の利用者が入力した情報により、期せずして本人が特定できてしまった場合
              </li>
              <li>
                ご本人以外の利用者が個人を識別できる情報（ID・パスワード等）を入手した場合
              </li>
              <li>
                その他当事務局の故意または重大な過失なく不正に個人情報が取得された場合
              </li>
            </UlCircle>
          </Section>
          <Section>
            <h2>７.セキュリティについて</h2>
            <Text>
              個人情報を取り扱うページについては、利用者の安全性確保のためSSL（Secure
              Socket Layer、暗号通信技術の一種）を利用しております。
            </Text>
          </Section>
          <Section>
            <h2>８.Cookie（クッキー）の使用について</h2>
            <Text>
              Cookieとは、サーバーコンピュータからお客様のブラウザに返信され、お客様が使用しているコンピュータのハードディスクに蓄積される情報です。
              <br />
              当事務局は、利用者の皆様によりよいサービスを提供するために、Cookieを使用することがありますが、これにより個人を特定する情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。
              <br />
              なお、お使いのブラウザで、Cookieの使用を許可しないサイトに本キャンペーンサイトが設定されている場合は、適切なサービスが提供されない場合がありますので、適切なサービスを希望される場合は、ブラウザの設定をご確認ください。
            </Text>
          </Section>
          <Section>
            <h2>９.個人情報保護方針について</h2>
            <Text>
              当事務局の個人情報保護方針は、以下の通りです。
              <br />
              <Link
                href={
                  isMobile
                    ? "https://www.boatrace.jp/owsp/sp/extra/privacy.html"
                    : "https://www.boatrace.jp/owpc/pc/extra/privacy.html"
                }
                target="_blank"
                rel="noreferrer"
              >
                {isMobile
                  ? "https://www.boatrace.jp/owsp/sp/extra/privacy.html"
                  : "https://www.boatrace.jp/owpc/pc/extra/privacy.html"}
              </Link>
            </Text>
          </Section>
          <Section>
            <h2>10.お問合せ先について</h2>
            <Text>
              利用者は、当事務局が保有する利用者の個人情報又は第三者提供記録について、当事務局所定の手続により開示をご請求いただくことができます。利用者の個人情報又は第三者提供記録の開示を希望される場合は、「
              あなただけに特別ご招待キャンペーン事務局」までお問い合わせください。開示に必要な手続についてご案内いたします。
              <br />
              その他、利用者の個人情報の訂正・利用停止・消去等をお求めになりたい場合、個人情報の安全管理措置に関するご質問等、個人情報に関するお問い合わせやご相談につきましても、「あなただけに特別ご招待キャンペーン事務局」で承ります。
            </Text>
          </Section>
          <Button onClick={() => goTopAndScroll()}>戻る</Button>
        </Content>
      </Main>
      <Footer />
      <Copyright id="copyright">
        Copyright TELEBOAT All Rights Reserved.
      </Copyright>
    </Wrapper>
  );
};
