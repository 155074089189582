import React from "react";
import styled from "@emotion/styled";
import LogoSvg from "@/ui/images/logo.svg";

const Wrapper = styled.div`
  width: 100%;
  background-color: #002664;
  text-align: center;
  padding: 20px 0;
  position: fixed;
  top: 0;
`;

export const Header = () => {
  return (
    <Wrapper>
      <img src={LogoSvg} alt="TELEBOAT" />
    </Wrapper>
  );
};
