import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0,-4px,0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
`;

const Text = styled.span`
  color: #fff;
  background-color: #ff0000;
  padding: 4px 15px;
  border-radius: 6px;
  font-size: 9px;
  animation: ${fadeIn} 0.2s ease-out 1;
  opacity: 1;
  font-weight: bold;
`;

/**
 * バリデーションエラーの赤文字表示
 */
export const ValidationError = ({ message }) => {
  if (!message) return null;
  return <Text>{message}</Text>;
};
