import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Input } from "@/ui/components/elements/Input/Input";
import { Checkbox } from "@/ui/components/elements/Checkbox/Checkbox";
import { FormLabel } from "@/ui/components/elements/FormLabel/FormLabel";
import { applicationFormContext } from "@/application/contexts/useApplicationForm";
import IconClose from "@/ui/images/icon_close.svg";

const Wrapper = styled.form`
  position: relative;
  border: solid 2px #002664;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.isOpen ? "94%" : "70%")};
  margin-left: auto;
  margin-right: auto;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  background-color: #002664;
  border: 0;
  border-radius: 500px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  padding: 15px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 22px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  width: 17px;
  height: 17px;
  background-color: #fff;
  padding: 0;
  cursor: pointer;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};

  img {
    display: block;
    width: 17px;
    height: 17px;
  }
`;

const Title = styled.div`
  padding: 28px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`;

const FromInner = styled.div`
  overflow: hidden;
  transition: height 0.3s ease-in-out 0.2s, padding 0.3s ease-in-out 0.2s;
  height: ${(props) => (props.isOpen ? "auto" : "0")};
  width: 89%;
  margin-left: auto;
  margin-right: auto;
  padding: ${(props) => (props.isOpen ? " 20px 0 30px 0" : "0")};
`;

const NameContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
`;

const AgreeContainer = styled.div`
  margin-bottom: 28px;
  margin-top: 13px;

  fieldset {
    border: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
  }

  legend {
    text-align: center;
    margin-bottom: 10px;
  }
`;

const BlueText = styled.span`
  color: #4fb4ca;
  padding-bottom: 1px;
  background-image: linear-gradient(to bottom, #4fb4ca 0%, #4fb4ca 100%);
  background-repeat: no-repeat;
  background-size: 100% 1.5px;
  background-position: 0 90%;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  border-radius: 5px;
  background: #f0f4fa;
  text-align: center;
  width: 48%;
  padding: 10px 0;
  margin-left: auto;
  margin-right: auto;
`;

export const ApplicationForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { register, handleSubmit, errors, agreeChecked, onCheckboxChange } =
    useContext(applicationFormContext);

  const handlePageChange = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  useEffect(() => {
    if (window.location.search.includes("back")) {
      setIsOpen(true);
      window.location.hash = "a-step3";
    }
  }, []);

  return (
    <Wrapper isOpen={isOpen}>
      <CloseButton
        type="button"
        onClick={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <img src={IconClose} alt="close" />
      </CloseButton>
      <Title onClick={() => setIsOpen(true)}>応募フォーム入力</Title>
      <FromInner isOpen={isOpen}>
        <FormLabel title="テレボート加入者番号" />
        <InputWrapper>
          <Input
            id="subscriber_number"
            {...register("subscriber_number", { required: true })}
            error={errors.subscriber_number?.message}
          />
        </InputWrapper>
        <NameContainer>
          <div>
            <FormLabel title="カナ氏名（セイ）" />
            <InputWrapper>
              <Input
                id="last_name_kana"
                {...register("last_name_kana", { required: true })}
                error={
                  errors.last_name_kana?.message ||
                  errors.fast_name_kana?.message
                }
              />
            </InputWrapper>
          </div>
          <div>
            <FormLabel title="カナ氏名（メイ）" />
            <InputWrapper>
              <Input
                id="fast_name_kana"
                {...register("fast_name_kana", { required: true })}
              />
            </InputWrapper>
          </div>
        </NameContainer>
        <FormLabel title="メールアドレス" />
        <InputWrapper>
          <Input
            id="email"
            {...register("email", { required: true })}
            error={errors.email?.message}
          />
        </InputWrapper>
        <FormLabel title="メールアドレス(確認用)" />
        <InputWrapper>
          <Input
            id="email_confirm"
            {...register("email_confirm", { required: true })}
            error={errors.email_confirm?.message}
          />
        </InputWrapper>
        <AgreeContainer>
          <fieldset>
            <legend>
              <BlueText onClick={() => handlePageChange("/information_rules/")}>
                ご利用規約
              </BlueText>
              及び
              <BlueText
                onClick={() => handlePageChange("/information_privacy/")}
              >
                個人情報の取り扱い
              </BlueText>
              に
              <br />
              ついて同意する
            </legend>
            <CheckboxContainer>
              <Checkbox
                id="agree"
                error={errors.agree?.message}
                checked={agreeChecked}
                onChange={onCheckboxChange}
              >
                同意する
              </Checkbox>
            </CheckboxContainer>
          </fieldset>
        </AgreeContainer>
        <Button
          type="button"
          onClick={handleSubmit(() =>
            handlePageChange("/application/confirm/")
          )}
        >
          確認する
        </Button>
      </FromInner>
    </Wrapper>
  );
};
