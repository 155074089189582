import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ContextConfig from "./ContextConfig";
import { Top } from "@/ui/pages/Top/Top";
import { Confirm } from "@/ui/pages/Confirm/Confirm";
import { Complete } from "@/ui/pages/Complete/Complete";
import { InformationCampaign } from "@/ui/pages/InformationCampaign/InformationCampaign";
import { InformationPrivacy } from "@/ui/pages/InformationPrivacy/InformationPrivacy";
import { InformationRules } from "@/ui/pages/InformationRules/InformationRules";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ContextConfig />,
    children: [
      { index: true, element: <Top /> },
      { path: "application/confirm/", element: <Confirm /> },
      { path: "application/complete/", element: <Complete /> },
      { path: "information_campaign/", element: <InformationCampaign /> },
      { path: "information_privacy/", element: <InformationPrivacy /> },
      { path: "information_rules/", element: <InformationRules /> },
    ],
  },
]);
const RouterConfig = () => {
  return <RouterProvider router={router} />;
};

export default RouterConfig;
