import styled from "@emotion/styled";

const Label = styled.label`
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
`;

export const FormLabel = ({ title }) => {
  return <Label>{title}</Label>;
};
