import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import SitetitleSp from "@/ui/images/sitetitle_sp.png";
import { Footer } from "@/ui/components/panes/Footer/Footer";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
`;

const Wrapper = styled.div`
  background-color: #002664;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const PageTitle = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  display: block;
  width: 215px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  img {
    display: block;
    width: 215px;
  }
`;

const Main = styled.div`
  padding-bottom: 80px;

  h1 {
    width: 91.6030534351%;
    max-width: 700px;
    background-color: #4fb4ca;
    border-radius: 16px 16px 0px 0px;
    text-align: center;
    font-size: 1.125rem;
    color: #fff;
    padding: 12px 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  width: 91.6030534351%;
  max-width: 700px;
  position: relative;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  padding: 37px 0 80px 0;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;

  h2 {
    border-bottom: solid 1px #002664;
    font-size: 1rem;
    line-height: 1em;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  h2:before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: #4fb4ca;
    border-radius: 500px;
    margin-right: 5px;
  }
`;

const Skyblue = styled.span`
  color: #4fb4ca;
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-bottom: 1em;
`;

const UlCircle = styled.ul`
  list-style-type: disc;
  padding-left: 1em;

  li {
    font-size: 0.875rem;
    line-height: 1.625rem;
  }
`;

const Button = styled.button`
  position: absolute;
  bottom: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 175px;
  border-radius: 500px;
  background-color: #4fb4ca;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 1.125rem;
  padding: 13px 0;
`;

export const InformationRules = () => {
  const navigate = useNavigate();

  const goTopAndScroll = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  return (
    <Wrapper>
      <PageTitle onClick={() => navigate("/")}>
        <img src={SitetitleSp} alt="利用規約" />
      </PageTitle>
      <Main>
        <h1>ご利用規約</h1>
        <Content>
          <Section>
            <Text>
              一般財団法人BOAT RACE 振興会が運営する、
              <Skyblue>「あなただけに特別ご招待キャンペーン」</Skyblue>
              (以下「本キャンペーン」)
              にご応募いただく前に、以下の「ご応募にあたっての注意」、および「個人情報の取り扱いについて」をよくお読みいただき、諸条件に同意の上、ご応募ください。ご応募いただいた場合には、本利用規約で明示されている諸条件すべてにご同意いただいたものとさせていただきます。なお、本キャンペーンの内容および本利用規約は、事前に通知することなく改定することがございます。あらかじめご了承ください。
            </Text>
          </Section>
          <Section>
            <h2>ご応募にあたってのご注意</h2>
            <Text>
              当事務局は、利用者に当事務局のサービス等に関する情報を提供するため、利用者のお名前・住所・生年月日・性別・電話番号・e-mailアドレスといった個人情報を、利用者よりご提供いただきます。
            </Text>

            <UlCircle>
              <li>
                本キャンペーンのご応募にあたり、以下の事項についてあらかじめご了承ください。
              </li>
              <li>
                本キャンペーンは、スマートフォンから本応募サイトにてご応募された方に限ります。
              </li>
              <li>
                本キャンペーンは、日本国内在住の方に限らせていただきます。
              </li>
              <li>
                本キャンペーンは、テレボート（インターネット）会員の方に限らせていただきます。（20歳未満の方、営業性・事業性個人、法人の方はテレボートにご登録いただけません）
              </li>
              <li>当選内容についてのお問い合わせは、一切お答えしかねます。</li>
              <li>
                本キャンペーンのご応募はお一人様につき１回限りとさせていただきます。
              </li>
              <li>
                複数口座をお持ちの方など複数回ご応募された場合、無効とさせていただきます。
              </li>
              <li>
                本キャンペーンの運営体制や抽選方法等については一切お答えしかねます。
              </li>
              <li>当選権利の譲渡は出来ません。</li>
              <li>
                賞金は、テレボートに登録されている銀行口座にお振込みいたします。振込名義は「トクベツゴショウタイキャンペーンジムキョク」です。
              </li>
              <li>
                当選発表以前に、テレボート（インターネット）を解約もしくは、銀行口座を解約された場合、並びに、何等かの理由で口座振込ができない場合は、当選を無効とさせていただきます。
                <br />
                賞金および賞品に起因する事故、障害、犯罪、紛争等については、当会は一切の責任を負いません。
              </li>
              <li>
                本キャンペーンの内容、賞金、賞品、サイト仕様、スケジュール等は予告なく変更する場合があります。
              </li>
              <li>
                ご応募に関わる必要な通信費等は、お客さまのご負担となります。
              </li>
              <li>
                ご応募いただいたお客さまの個人情報は「個人情報の取り扱いについて」に基づき適切に取扱います。
              </li>
              <li>
                ご応募に伴う使用機器・通信における障害、損傷についての責任は一切負いかねます。
              </li>
              <li>
                一部ご利用いただけない機種がございます。予めご了承下さい。
              </li>
              <li>
                本キャンペーンに携わっている関係者からの応募はできません。
              </li>
            </UlCircle>
          </Section>

          <Button onClick={() => goTopAndScroll()}>戻る</Button>
        </Content>
      </Main>
      <Footer />
      <Copyright id="copyright">
        Copyright TELEBOAT All Rights Reserved.
      </Copyright>
    </Wrapper>
  );
};
