import React from "react";
import styled from "@emotion/styled";
import KvSpWebp from "@/ui/images/kv_sp.webp";
import KvSpImg from "@/ui/images/kv_sp.png";
import KvDateSpImg from "@/ui/images/kv_date_sp.png";

const Wrapper = styled.section`
  background-color: #002664;
`;

const SpImg = styled.img`
  display: block;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const KvDate = styled.div`
  top: -50px;
  position: relative;

  h2 {
    position: absolute;
    z-index: 1;
    top: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 293px;
    border-radius: 500px;
    background-color: #fff;
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const DateText = styled.div`
  background-color: #d9d9d9;
  padding: 50px 0 38px 0;

  img {
    display: block;
    width: 343px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Kv = () => {
  return (
    <Wrapper id="kv">
      <picture>
        <source type="image/webp" srcSet={KvSpWebp} />
        <SpImg src={KvSpImg} alt="あなただけに特別ご招待キャンペーン" />
      </picture>
      <KvDate id="kv_date">
        <h2>キャンペーン応募期間</h2>
        <DateText>
          <img src={KvDateSpImg} alt="2024年5月15日から2024年12月31日まで" />
        </DateText>
      </KvDate>
    </Wrapper>
  );
};
