import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Header } from "@/ui/components/panes/Header/Header";
import { applicationFormContext } from "@/application/contexts/useApplicationForm";
import { FormLabel } from "@/ui/components/elements/FormLabel/FormLabel";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
`;

const Wrapper = styled.div`
  padding-top: 70px;
  height: 100svh;
  background-color: #002664;
`;

const Item = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
`;

const FormContent = styled.div`
  padding: 10px 20px 100px 20px;
  max-width: 700px;
  background-color: #fff;
  height: fit-content;
  margin: 0 auto;
`;

const Text = styled.div`
  margin-top: 10px;
  font-size: 15px;
`;

const Button = styled.button`
  display: block;
  background-color: #002664;
  border: 0;
  border-radius: 500px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  padding: 15px 100px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  margin: 20px auto 0 auto;
  gap: 17px;
  display: flex;
  flex-direction: column;
`;

const ConfirmText = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

export const Confirm = () => {
  const { watch, submit, handleSubmit } = useContext(applicationFormContext);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await submit(data);
  };

  return (
    <>
      <Header />
      <Wrapper>
        <FormContent>
          <Item>
            <FormLabel title="テレボート加入者番号" />
            <Text>{watch("subscriber_number")}</Text>
          </Item>
          <Item>
            <FormLabel title="カナ氏名（セイ）" />
            <Text>{watch("last_name_kana")}</Text>
          </Item>
          <Item>
            <FormLabel title="カナ氏名（メイ）" />
            <Text>{watch("fast_name_kana")}</Text>
          </Item>
          <Item>
            <FormLabel title="メールアドレス" />
            <Text>{watch("email")}</Text>
          </Item>
          <ConfirmText>こちらの内容で応募します。</ConfirmText>
          <ButtonWrapper>
            <Button type="button" onClick={handleSubmit(onSubmit)}>
              応募する
            </Button>
            <Button type="button" onClick={() => navigate("/?back=1")}>
              戻る
            </Button>
          </ButtonWrapper>
        </FormContent>
      </Wrapper>
      <Copyright>Copyright TELEBOAT All Rights Reserved.</Copyright>
    </>
  );
};
