import React from "react";
import styled from "@emotion/styled";
import ScheduleImg from "@/ui/images/about_schedule_sp.png";
import ScheduleWebp from "@/ui/images/about_schedule_sp.webp";

const Wrapper = styled.div`
  padding: 45px 0 50px 0;
`;

const H2 = styled.h2`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 85%;
  max-width: 700px;
  font-size: 18px;
  color: #4fb4ca;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  white-space: nowrap;

  &:before,
  &:after {
    content: "";
    display: block;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 16%;
    height: 2px;
    background-color: #4fb4ca;
  }

  &:before {
    margin-right: 20px;
  }

  &:after {
    margin-left: 20px;
  }
`;

const Ul = styled.ul`
  width: 85%;
  max-width: 700px;
  list-style-type: none;
  padding: 0;
  margin: 0 auto 50px auto;
`;

const Li = styled.li`
  font-size: 0.875rem;
  line-height: 1.625rem;

  span {
    color: #4fb4ca;
  }
`;

const Schedule = styled.img`
  display: block;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

export const About = () => {
  return (
    <Wrapper>
      <H2>キャンペーンについて</H2>
      <Ul>
        <Li>
          <span>●</span>
          このキャンペーンは、エントリーいただいた方のみ抽選の権利を得られます。
        </Li>
        <Li>
          <span>●</span>
          応募方法の条件をすべて達成した日の次の締切日(毎週火曜日)に抽選し、翌週金曜日頃に当選者へ振込を行います。
        </Li>
        <Li>
          <span>●</span>
          手順が週をまたがっても5月15日(水)～12月31日(火)までの間に全ての手順が達成されれば有効です。
          <br />
          （例：エントリーが5月最終週、購入条件達成が10月最終週→翌月11月初週以降に抽選·振込）
        </Li>
      </Ul>
      <H2>抽選スケジュール</H2>
      <a href={ScheduleImg} target="_blank" rel="noreferrer">
        <picture>
          <source type="image/webp" srcSet={ScheduleWebp} />
          <Schedule src={ScheduleImg} alt="抽選スケジュール" />
        </picture>
      </a>
    </Wrapper>
  );
};
