import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Component } from "react";

const increase = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Notice = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 3;
  display: block;
  width: 330px;
  padding: 10px;
  color: #fff;
  border-radius: 3px;
  background-color: rgba(221, 80, 63, 0.7);
  overflow: auto;
  opacity: 0;
  animation-name: ${increase};
  animation-duration: 5s;
  &:empty {
    display: none;
  }
`;

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMsg: null };
  }

  updateError(e) {
    if (e.reason.message.includes("Failed to fetch")) {
      this.setState({
        errorMsg: "通信に失敗しました。通信環境をご確認ください。",
      });
    } else if (e.reason.message) {
      this.setState({ errorMsg: e.reason.message });
    } else {
      this.setState({
        errorMsg:
          "エラーが発生しました。エラーが解消されない場合、お問い合わせください。",
      });
    }
    setTimeout(() => {
      this.setState({ errorMsg: null });
    }, 5000);
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.updateError.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.updateError.bind(this)
    );
  }

  render() {
    return (
      <>
        <Notice>{this.state.errorMsg}</Notice>
        {this.props.children}
      </>
    );
  }
}
