import { getCsrfToken } from "@/utils/auth";

const PATH = `${process.env.REACT_APP_API_URL}/apply_campaign`;
const campaign_code = process.env.REACT_APP_CAMPAIGN_CODE || "";

export class ApplyCampaignClient {
  async post(input) {
    const { fast_name_kana, last_name_kana, email, subscriber_number } = input;
    const method = "POST";
    const csrftoken = getCsrfToken();
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    };
    const body = JSON.stringify({
      fast_name_kana,
      last_name_kana,
      email,
      subscriber_number,
      campaign_code,
    });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      if (!response.ok) {
        throw new Error("処理に失敗しました");
      }
      return response.json();
    } catch {
      throw new Error("処理に失敗しました");
    }
  }
}
