import React from "react";
import styled from "@emotion/styled";
import { Kv } from "@/ui/components/patterns/Kv/Kv";
import { ApplicationSteps } from "@/ui/components/patterns/ApplicationSteps/ApplicationSteps";
import { About } from "@/ui/components/patterns/About/About";
import { Info } from "@/ui/components/patterns/Info/Info";
import { Footer } from "@/ui/components/panes/Footer/Footer";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
`;

export const Top = () => {
  return (
    <>
      <Kv />
      <ApplicationSteps />
      <About />
      <Info />
      <Footer />
      <Copyright id="copyright">
        Copyright TELEBOAT All Rights Reserved.
      </Copyright>
    </>
  );
};
