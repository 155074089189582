import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import StepLeadSpWebp from "@/ui/images/step_lead_sp.webp";
import KvSpImg from "@/ui/images/kv_sp.png";
import Step1TSpImg from "@/ui/images/step1_t_sp.png";
import Step2TSpImg from "@/ui/images/step2_t_sp.png";
import Step3TSpImg from "@/ui/images/step3_t_sp.png";
import TicketSpImg from "@/ui/images/step2_img_sp.png";
import IconArrowCircle from "@/ui/images/icon_arrow_circle.svg";
import IconPolygon from "@/ui/images/icon_polygon.svg";
import { isMobile } from "react-device-detect";
import { ApplicationForm } from "@/ui/components/patterns/ApplicationForm/ApplicationForm";

const Wrapper = styled.section`
  background-color: #002664;
  padding: 58px 0 30px 0;
`;

const StepLead = styled.div`
  img {
    display: block;
    width: 315px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 11px;
  }
`;

const StepItem = styled.div`
  position: relative;
  padding-bottom: 58px;
  margin-bottom: 20px;

  h2 {
    position: absolute;
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  img {
    display: block;
    width: 210px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StepItemContent = styled.div`
  position: relative;
  top: 58px;
  width: 91.6030534351%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 16px;
`;

const Step1ItemContent = styled(StepItemContent)`
  padding: 40px 0 30px 0;
`;

const Step2ItemContent = styled(StepItemContent)`
  padding: 48px 0 33px 0;

  a {
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 178px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Step3ItemContent = styled(StepItemContent)`
  padding: 60px 0 30px 0;

  h3 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 22px;
  }
`;

const BoatRound = styled.a`
  display: block;
  width: 86%;
  background-image: url(${IconArrowCircle});
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center right 10px;
  border-radius: 500px;
  border: solid 2px #002664;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  color: #002664;
  padding: 15px 0;
  margin-left: auto;
  margin-right: auto;

  &:active,
  &:visited {
    color: #002664;
    text-decoration: none;
  }
`;

const BoatRoundStep1 = styled(BoatRound)`
  margin-bottom: 11px;
`;

const Step1Slid = styled.div`
  width: 86%;
  margin-left: auto;
  margin-right: auto;

  button {
    display: block;
    background-color: #002664;
    width: 100%;
    height: 30px;
    padding: 0;
    line-height: 0;
    cursor: pointer;
  }

  button::after {
    content: "";
    display: block;
    width: 20px;
    height: 30px;
    background-image: url(${IconPolygon});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center center;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

const SlideText = styled.div`
  border: solid 2px #002664;
  border-top: 0;
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 0 24px;
  overflow: hidden;
  transition: height 0.5s ease, padding 0.5s ease;
  height: 0;
`;

const boatRaceUrl = `https://www.boatrace.jp/bosyu/${
  isMobile ? "sp" : "pc"
}/apply/`;

const mbraceUrl = isMobile
  ? "https://spweb.brtb.jp/"
  : "https://ib.mbrace.or.jp/";

export const ApplicationSteps = () => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.height = "auto";
      contentRef.current.style.padding = "28px 24px";
    } else {
      contentRef.current.style.height = "0px";
      contentRef.current.style.padding = "0 24px";
    }
  }, [isOpen]);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <StepLead>
        <picture>
          <source type="image/webp" srcSet={StepLeadSpWebp} />
          <img
            src={KvSpImg}
            alt="今、テレボート新規会員登録し、100円以上の舟券を購入して応募すると・・・毎週100名様に2,000円プレゼント！"
          />
        </picture>
      </StepLead>
      <StepItem>
        <h2>
          <img src={Step1TSpImg} alt="STEP1" />
        </h2>
        <Step1ItemContent>
          <BoatRoundStep1 href={boatRaceUrl} target="_blank">
            テレボート会員に新規登録
          </BoatRoundStep1>
          <Step1Slid isOpen={isOpen}>
            <button onClick={toggleContent} />
            <SlideText ref={contentRef}>
              <p>
                当キャンペーンの応募時に、「テレボート加入者番号」が必要になりますので、テレボート新規登録時に発行される「テレボート加入者番号」をお手元にお控えください。
              </p>
            </SlideText>
          </Step1Slid>
        </Step1ItemContent>
      </StepItem>
      <StepItem>
        <h2>
          <img src={Step2TSpImg} alt="STEP2" />
        </h2>
        <Step2ItemContent>
          <BoatRound href={mbraceUrl} target="_blank">
            100円以上の舟券を購入
          </BoatRound>
          <img src={TicketSpImg} alt="TICKETS" />
        </Step2ItemContent>
      </StepItem>
      <StepItem>
        <h2>
          <img src={Step3TSpImg} alt="STEP3" />
        </h2>
        <Step3ItemContent id="a-step3">
          <h3>キャンペーンに応募</h3>
          <ApplicationForm />
        </Step3ItemContent>
      </StepItem>
    </Wrapper>
  );
};
