import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { ValidationError } from "../ValidationError/ValidationError";

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const InputElement = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b1bccd;
  background: #f0f4fa;
  padding: 10px;
  font-size: 15px;
  font-family: "Hiragino Sans W3", "Hiragino Kaku Gothic ProN",
    "ヒラギノ角ゴ ProN W3", "游ゴシック体", "Yu Gothic Medium", YU Gothic,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #ccc;
  }
  &:disabled {
    color: #ccc;
  }
`;

const Counter = styled.span`
  position: absolute;
  bottom: -36px;
  right: 2px;
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

/**
 * フォーム 入力欄
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 */
export const Input = forwardRef((props, ref) => {
  return (
    <Wrapper>
      <InputElement {...props} ref={ref} />
      {props.max && (
        <Counter>
          {`${props.value}`.length ?? 0} / {props.max}
        </Counter>
      )}
      <Error>
        <ValidationError message={props.error} />
      </Error>
    </Wrapper>
  );
});

Input.displayName = "Input";
