import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Header } from "@/ui/components/panes/Header/Header";
import BannerImg from "@/ui/images/br_banner.jpeg";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
`;

const Wrapper = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #002664;

  strong {
    padding-bottom: 1px;
    background-image: linear-gradient(to bottom, #002664 0%, #002664 100%);
    background-repeat: no-repeat;
    background-size: 100% 1.5px;
    background-position: 0 90%;
  }
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
  background-color: #fff;
  padding: 90px 20px 110px 20px;
  text-align: center;
`;

const Button = styled.button`
  width: fit-content;
  margin: 30px auto 0 auto;
  display: block;
  background-color: #002664;
  border: 0;
  border-radius: 500px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  padding: 15px 100px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Link = styled.a`
  color: #4fb4ca;
  word-break: break-all;
  font-size: 0.875rem;
  line-height: 1.625rem;

  &:visited {
    color: #4fb4ca;
  }
`;

const Banner = styled.img`
  width: 100%;
  margin: 5px auto 0 auto;
`;

export const Complete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Wrapper>
        <Content>
          <p>
            あなただけに特別ご招待キャンペーンに
            <br />
            ご応募いただきありがとうございました。
            <br />
            <br />
            ご登録いただいたメールアドレス宛に
            <br />
            キャンペーン登録メールを
            <br />
            お送りしております。
            <br />
            <br />
            キャンペーンのご応募が完了するためには、
            <br />
            100円以上の舟券購入が必要となりますので、
            <br />
            舟券購入がまだお済みでない方は
            <br />
            キャンペーン期間内に舟券購入をお願いします。
            <br />
            <br />
            キャンペーン応募と舟券購入が完了した時点で
            <br />
            抽選の対象となります。
            <br />
            （毎週火曜日が応募締切となります。）
            <br />
            <br />
            <br />
            舟券購入はこちらから
            <br />
            <br />
            【スマホ】
            <br />
            <Link
              href="https://spweb.brtb.jp/"
              target="_blank"
              rel="noreferrer"
            >
              https://spweb.brtb.jp/
            </Link>
            <br />
            <br />
            【PC】
            <br />
            <Link
              href="https://ib.mbrace.or.jp/"
              target="_blank"
              rel="noreferrer"
            >
              https://ib.mbrace.or.jp/
            </Link>
            <br />
            <br />
            <br />
            さらに！！
            <br />
            ＼新規入会者限定！現金3,000円が当たる／
            <br />
            テレボートはじめて応援キャンペーンも実施中！
          </p>
          <a
            href="https://tb-beginner2024.jp/"
            target="_blank"
            rel="noreferrer"
          >
            <Banner src={BannerImg} alt="banner" />
          </a>
          <Button type="button" onClick={() => navigate("/")}>
            TOPへ戻る
          </Button>
        </Content>
      </Wrapper>
      <Copyright>Copyright TELEBOAT All Rights Reserved.</Copyright>
    </>
  );
};
