import styled from "@emotion/styled";
import React from "react";
import { ValidationError } from "../ValidationError/ValidationError";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  display: inline;
  margin-right: 5px;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
`;

const Label = styled.label`
  ${(props) => !props.disabled && "cursor: pointer"};
  text-align: left; // 改行時に左揃えになるように
`;

const Error = styled.span`
  position: absolute;
  bottom: -18px;
  left: 15px;
  white-space: nowrap;
`;

/**
 * チェックボックス
 */
export const Checkbox = ({
  id,
  checked = false,
  disabled = false,
  onChange,
  children,
  error,
}) => {
  return (
    <Wrapper>
      <Input
        id={id}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      <Label htmlFor={id} disabled={disabled}>
        <span>{children}</span>
      </Label>
      <Error>
        <ValidationError message={error} />
      </Error>
    </Wrapper>
  );
};
