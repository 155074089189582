import {
  applicationFormContext,
  useApplicationFormContext,
} from "@/application/contexts/useApplicationForm";
import { Outlet } from "react-router-dom";

const ContextConfig = () => {
  const applicationCtx = useApplicationFormContext();

  return (
    <applicationFormContext.Provider value={applicationCtx}>
      <Outlet />
    </applicationFormContext.Provider>
  );
};
export default ContextConfig;
