import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";

const Wrapper = styled.nav`
  width: 100%;
  border-top: solid 2px #fff;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  position: ${(props) => (props.isBottom ? "relative" : "fixed")};

  bottom: 0px;
  transform: translateY(${(props) => (props.show ? "0px" : "100px")});
  transition: transform 0.5s, opacity 0.5s, bottom 0.2s;
  z-index: 999999999;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  width: 33%;
  height: 115px;
  background-color: #002664;
  text-decoration: none;
  color: #fff;

  &:visited {
    color: #fff;
  }

  span {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

export const Footer = ({ isBottomFixed = false }) => {
  const [show, setShow] = useState(isBottomFixed);
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const kvDate = document.querySelector("#kv_date");
    const kv = document.querySelector("#kv");
    if (!kvDate || !kv) return;

    const showPoint = kvDate.getBoundingClientRect().top + window.scrollY;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    setShow(window.scrollY > showPoint);
    const isBottom =
      scrollTop + window.innerHeight + 70 >=
      document.documentElement.offsetHeight - 70;

    setIsBottom(isBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleScroll);

    const handleResize = () => {
      clearTimeout(window.resizeTimer);
      window.resizeTimer = setTimeout(handleScroll, 200);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleScroll);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);

      clearTimeout(window.resizeTimer);
    };
  }, []);

  return (
    <Wrapper show={show} isBottom={isBottom}>
      <Link
        href={`https://www.boatrace.jp/bosyu/${isMobile ? "sp" : "pc"}/apply/`}
        target="_blank"
      >
        <span>
          テレボート
          <br />
          会員に登録
        </span>
      </Link>
      <Link href="https://spweb.brtb.jp/" target="_blank">
        <span>
          舟券購入は
          <br />
          こちら
        </span>
      </Link>
      <Link href="#a-step3">
        <span>応募する</span>
      </Link>
    </Wrapper>
  );
};
