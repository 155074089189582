import { createContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApplyCampaignClient } from "@/infrastructure/apiClients/ApplyCampaign/ApplyCampaign";

const defaultContext = {
  register: () => {},
  handleSubmit: () => {},
  errors: {},
  submit: () => {},
  watch: () => {},
  onCheckboxChange: () => {},
  agreeChecked: false,
};

export const applicationFormContext = createContext(defaultContext);
const schema = yup
  .object({
    fast_name_kana: yup.string().required().katakana(),
    last_name_kana: yup.string().required().katakana(),
    email: yup.string().email().required(),
    email_confirm: yup
      .string()
      .oneOf([yup.ref("email")], "メールアドレスが一致しません"),
    subscriber_number: yup
      .string()
      .required()
      .matches(/^\d{8}$/, {
        message: "半角数字8文字で入力してください",
      }),
    agree: yup.boolean().required().oneOf([true], "同意が必要です"),
  })
  .required();

export const useApplicationFormContext = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const agreeChecked = watch("agree", false);

  const onCheckboxChange = () => {
    setValue("agree", !agreeChecked, { shouldValidate: true });
  };

  const submit = async (data) => {
    const client = new ApplyCampaignClient();
    try {
      await client.post(data);
      navigate("/application/complete/");
    } catch (e) {
      console.error(e.message);
      throw new Error(e.message);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    submit,
    watch,
    onCheckboxChange,
    agreeChecked,
  };
};
